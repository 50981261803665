import React from 'react';
import './Products.css';
import { useState } from 'react';
import ProductDetail from './ProductDetail';

const ProductCard = ({ imageUrl, heading, onClick }) => {
    return (
      <div className="col-6 col-md-3 col-lg-3 mb-4 box-with-shadow" style={{ padding: "10px" }} onClick={onClick}>
        <div className="card h-100">
          <img className="card-img-top" src={imageUrl} alt={heading} style={{ height: '250px', width: 'auto' }} />
          <div className="card-body">
            <h4 className="card-title">{heading}</h4>
            {/* Additional details like price can be added here */}
          </div>
        </div>
      </div>
    );
  }

  const LubricantsPage = () => {
    const [selectedProduct, setSelectedProduct] = useState(null);
  
    const products = [
      { imageUrl: 'barrel4.jpg',imageUrl2: 'barrel4.jpg', heading: 'WAY LUBE' , docxDownloadUrl: 'DATA-SHEET/OJAS SLIDEWAY.pdf'},
      { imageUrl: 'barrel4.jpg',imageUrl2: 'barrel4.jpg', heading: 'HLP Oils' , docxDownloadUrl: 'DATA-SHEET/HLP SWIFT OILS.pdf'},
      { imageUrl: 'PICTURES-OJAS/AW - 46/AW - 46.jpg',imageUrl2: 'PICTURES-OJAS/AW - 46/AW - 46 (2).jpg', heading: 'Hydraulic Oil' , docxDownloadUrl: 'DATA-SHEET/PREMIUM HYD OIL S.pdf'},
      // Add more products as needed
    ];

    const handleProductClick = (product) => {
      setSelectedProduct(product);
    }
  
    const handleCloseModal = () => {
      setSelectedProduct(null);
    }

    const handlePdfDownload = (pdfDownloadUrl) => {
      // Constructing the URL based on the file location
      const url = `${process.env.PUBLIC_URL}/${pdfDownloadUrl}`;
      console.log("PDF Download URL:", url);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = pdfDownloadUrl; // Set the correct filename
      document.body.appendChild(link);
  
      link.addEventListener('error', (event) => {
          console.error('Error downloading file:', event);
          // Handle error here, such as displaying a message to the user
      });
  
      link.click();
      document.body.removeChild(link);
  };
   
    return (
      <div>
          <div className='container'> 
        <img src='./AllNeedOils2.jpeg' className='products-img' alt=''/>
        </div>
        {/* <div className='container' style={{ height: '200px', width: '100%', position: 'relative', backgroundImage: 'url(bg-head.png)', backgroundSize: 'cover', backgroundPosition: 'center' , marginTop:'0px'}}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center' }}>
            <h1 className="future-head" style={{ color: "#031026" }}>Lubricants</h1>
            <div className="underline" style={{ color: "#031026" }}></div>
          </div>
        </div> */}
  
        <div className='container'>
          <div className="row">
            {products.map((product, index) => (
              <ProductCard
                key={index}
                imageUrl={product.imageUrl}
                heading={product.heading}
                onClick={() => handleProductClick(product)}
              />
            ))}
          </div>
        </div>
  
        {selectedProduct && (
          <ProductDetail
          imageUrl={selectedProduct.imageUrl}
          imageUrl2={selectedProduct.imageUrl}
          imageUrl3={selectedProduct.imageUrl2}
            heading={selectedProduct.heading}
            docxDownloadUrl={selectedProduct.docxDownloadUrl} // Use the correct prop name
        onDownload={() => handlePdfDownload(selectedProduct.docxDownloadUrl)}
            onClose={handleCloseModal}
          />
        )}
  
        <div className='container'>
          <h1 className="future-head" style={{ color: "Black" }}>Why Lubricants are essential </h1>
          <p className='info'>
Automotive oils are essential for the smooth and efficient operation of internal combustion engines, serving a multitude of critical functions that ensure the longevity, performance, and reliability of vehicles across various conditions and driving scenarios.Firstly, automotive oils act as lubricants, forming a protective barrier between moving engine parts, such as pistons, crankshafts, and camshafts, reducing friction and wear. This friction reduction not only extends the lifespan of engine components but also enhances fuel efficiency by minimizing energy losses due to frictional resistance.</p>
        </div>
      </div>
    );
  }
  

const IndustrialOilsPage = () => {

    const [selectedProduct, setSelectedProduct] = useState(null);
  
    const products = [
        { imageUrl: 'barrel4.jpg',imageUrl2: 'barrel4.jpg', heading: 'HEAT TRANSFER OIL' , docxDownloadUrl: 'DATA-SHEET/data-sheet.pdf'},
        { imageUrl: 'barrel4.jpg', imageUrl2: 'barrel4.jpg',heading: 'PUNCHING AND STAMPING OIL', docxDownloadUrl: 'DATA-SHEET/data-sheet.pdf'},       
        { imageUrl: 'barrel4.jpg',imageUrl2: 'barrel4.jpg', heading: 'TURBINE OIL' , docxDownloadUrl: 'DATA-SHEET/data-sheet.pdf'},
        { imageUrl: 'PICTURES-OJAS/CUTTING OIL/CUTTING OIL.jpg', imageUrl2: 'PICTURES-OJAS/CUTTING OIL/CUTTING OIL (2).jpg', heading: 'CUTTING OIL', docxDownloadUrl: 'DATA-SHEET/clear cut cutting oil.pdf' },
    ];

    const handleProductClick = (product) => {
      setSelectedProduct(product);
    }
  
    const handleCloseModal = () => {
      setSelectedProduct(null);
    }

    const handlePdfDownload = (pdfDownloadUrl) => {
      // Constructing the URL based on the file location
      const url = `${process.env.PUBLIC_URL}/${pdfDownloadUrl}`;
      console.log("PDF Download URL:", url);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = pdfDownloadUrl; // Set the correct filename
      document.body.appendChild(link);
  
      link.addEventListener('error', (event) => {
          console.error('Error downloading file:', event);
          // Handle error here, such as displaying a message to the user
      });
  
      link.click();
      document.body.removeChild(link);
  };
  
    return (
      <div>
         <div className='container'> 
        <img src='./AllNeedOils2.jpeg' className='products-img' alt=''/>
        </div>
        {/* <div className='container' style={{ height: '200px', width: '100%', position: 'relative', backgroundImage: 'url(bg-head.png)', backgroundSize: 'cover', backgroundPosition: 'center' , marginTop:'0px'}}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center' }}>
            <h1 className="future-head" style={{ color: "#031026" }}>Lubricants</h1>
            <div className="underline" style={{ color: "#031026" }}></div>
          </div>
        </div> */}
  
        <div className='container'>
          <div className="row">
            {products.map((product, index) => (
              <ProductCard
                key={index}
                imageUrl={product.imageUrl}
                heading={product.heading}
                onClick={() => handleProductClick(product)}
              />
            ))}
          </div>
        </div>
  
        {selectedProduct && (
          <ProductDetail
          imageUrl={selectedProduct.imageUrl}
          imageUrl2={selectedProduct.imageUrl}
          imageUrl3={selectedProduct.imageUrl2}
            heading={selectedProduct.heading}
            onClose={handleCloseModal}
            docxDownloadUrl={selectedProduct.docxDownloadUrl} // Use the correct prop name
            onDownload={() => handlePdfDownload(selectedProduct.docxDownloadUrl)}
          />
        )}
  
        <div className='container'>
          <h1 className="future-head" style={{ color: "Black" }}>Why Industrial Oils are essential </h1>
          <p className='info'>
          Industrial oils are essential for the smooth and efficient operation of internal combustion engines, serving a multitude of critical functions that ensure the longevity, performance, and reliability of vehicles across various conditions and driving scenarios.Firstly, automotive oils act as lubricants, forming a protective barrier between moving engine parts, such as pistons, crankshafts, and camshafts, reducing friction and wear. This friction reduction not only extends the lifespan of engine components but also enhances fuel efficiency by minimizing energy losses due to frictional resistance.</p>
        </div>
      </div>
    );
}

const AutomotiveOilsPage = () => {
    const [selectedProduct, setSelectedProduct] = useState(null);
  
    const products = [
      { imageUrl: 'PICTURES-OJAS/15W40/15W40.jpg', imageUrl2: 'PICTURES-OJAS/15W40/15W40back.jpg', heading: 'CI4 15W40 ENGINE OIL', docxDownloadUrl: 'DATA-SHEET/premium power cf4 15w40.pdf' },
      { imageUrl: 'PICTURES-OJAS/20W40/20W40.jpg', imageUrl2: 'barrel4.jpg', heading: '20W40 ENGINE OIL',docxDownloadUrl: 'DATA-SHEET/POWER GOLD 20W40.pdf'  },
      { imageUrl: 'PICTURES-OJAS/20W50/20W50.jpg', imageUrl2: 'PICTURES-OJAS/20W50/20W50back.jpg', heading: 'CF4 15W40 ENGINE OIL',docxDownloadUrl: 'DATA-SHEET/SUPER GOLD Ci-4 Plus 15W40 Oil.pdf' },
    ];
  
    const handleProductClick = (product) => {
      setSelectedProduct(product);
    }
  
    const handleCloseModal = () => {
      setSelectedProduct(null);
    }
  
    const handlePdfDownload = (pdfDownloadUrl) => {
        // Constructing the URL based on the file location
        const url = `${process.env.PUBLIC_URL}/${pdfDownloadUrl}`;
        console.log("PDF Download URL:", url);
    
        const link = document.createElement('a');
        link.href = url;
        link.download = pdfDownloadUrl; // Set the correct filename
        document.body.appendChild(link);
    
        link.addEventListener('error', (event) => {
            console.error('Error downloading file:', event);
            // Handle error here, such as displaying a message to the user
        });
    
        link.click();
        document.body.removeChild(link);
    };
    
  
    return (
      <div>
        <div className='container'> 
        <img src='./EngineOil2.jpeg' className='products-img' alt=''/>
        </div>

        <div className='container'>
          <div className="row">
            {products.map((product, index) => (
              <ProductCard
                key={index}
                imageUrl={product.imageUrl}
                heading={product.heading}
                onClick={() => handleProductClick(product)}
              />
            ))}
          </div>
        </div>
  
        {selectedProduct && (
        <ProductDetail
        imageUrl={selectedProduct.imageUrl}
        imageUrl2={selectedProduct.imageUrl}
        imageUrl3={selectedProduct.imageUrl2}
        heading={selectedProduct.heading}
        docxDownloadUrl={selectedProduct.docxDownloadUrl} // Use the correct prop name
        onDownload={() => handlePdfDownload(selectedProduct.docxDownloadUrl)}
        onClose={handleCloseModal}
      />
        )}
  
        <div className='container'>
          <h1 className="future-head" style={{ color: "Black" }}>Why Automotive Oils are essential </h1>
          <p className='info'>
Automotive oils are essential for the smooth and efficient operation of internal combustion engines, serving a multitude of critical functions that ensure the longevity, performance, and reliability of vehicles across various conditions and driving scenarios.Firstly, automotive oils act as lubricants, forming a protective barrier between moving engine parts, such as pistons, crankshafts, and camshafts, reducing friction and wear. This friction reduction not only extends the lifespan of engine components but also enhances fuel efficiency by minimizing energy losses due to frictional resistance.</p>
        
        </div>
      </div>
    );
  }

const GreasesPage = ({ imageUrl, heading, price }) => {
    
    const [selectedProduct, setSelectedProduct] = useState(null);
  
    const products = [
      { imageUrl: 'PICTURES-OJAS/GREASE MP3/BHP_8090.jpg', imageUrl2: 'PICTURES-OJAS/GREASE MP3/BHP_8091.jpg', heading: 'MULTI-PURPOSE GREASE', docxDownloadUrl: 'DATA-SHEET/OJAS MP3.pdf' },
        { imageUrl: 'barrel4.jpg',imageUrl2:'barrel4.jpg' , heading: 'HIGH TEMPERATURE GREASE', docxDownloadUrl: 'DATA-SHEET/OJAS HT GREASE.pdf' },
        { imageUrl: 'PICTURES-OJAS/EP - 2/BHP_8113.jpg',imageUrl2:'PICTURES-OJAS/EP - 2/BHP_8114.jpg', heading: 'EP GREASE' , docxDownloadUrl: 'DATA-SHEET/OJAS PLATINUM EP GREASES.pdf'},
        { imageUrl: 'barrel4.jpg',imageUrl2:'barrel4.jpg', heading: 'SP HT GREASE',  docxDownloadUrl: 'DATA-SHEET/OJAS SP HT.pdf'},
        { imageUrl: 'PICTURES-OJAS/GREASE AP3/BHP_8095.jpg',imageUrl2:'PICTURES-OJAS/GREASE AP3/BHP_8097.jpg', heading: 'AP3 GREASE',  docxDownloadUrl: 'DATA-SHEET/AP3 LONG LIFE GREASE.pdf'},
    ];

    const handleProductClick = (product) => {
      setSelectedProduct(product);
    }
  
    const handleCloseModal = () => {
      setSelectedProduct(null);
    }

    const handlePdfDownload = (pdfDownloadUrl) => {
      // Constructing the URL based on the file location
      const url = `${process.env.PUBLIC_URL}/${pdfDownloadUrl}`;
      console.log("PDF Download URL:", url);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = pdfDownloadUrl; // Set the correct filename
      document.body.appendChild(link);
  
      link.addEventListener('error', (event) => {
          console.error('Error downloading file:', event);
          // Handle error here, such as displaying a message to the user
      });
  
      link.click();
      document.body.removeChild(link);
  };
  
    return (
      <div>
         <div className='container'> 
        <img src='./Automotive.jpg' className='products-img' alt=''/>
        </div>
        {/* <div className='container' style={{ height: '200px', width: '100%', position: 'relative', backgroundImage: 'url(bg-head.png)', backgroundSize: 'cover', backgroundPosition: 'center' , marginTop:'0px'}}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center' }}>
            <h1 className="future-head" style={{ color: "#031026" }}>Greases</h1>
            <div className="underline" style={{ color: "#031026" }}></div>
          </div>
        </div> */}
  
        <div className='container'>
          <div className="row">
            {products.map((product, index) => (
              <ProductCard
                key={index}
                imageUrl={product.imageUrl}
                heading={product.heading}
                onClick={() => handleProductClick(product)}
              />
            ))}
          </div>
        </div>
  
        {selectedProduct && (
          <ProductDetail
            imageUrl={selectedProduct.imageUrl}
            imageUrl2={selectedProduct.imageUrl}
            imageUrl3={selectedProduct.imageUrl2}
            heading={selectedProduct.heading}
            onClose={handleCloseModal}
            docxDownloadUrl={selectedProduct.docxDownloadUrl} // Use the correct prop name
            onDownload={() => handlePdfDownload(selectedProduct.docxDownloadUrl)}
          />
        )}
  
        <div className='container'>
          <h1 className="future-head" style={{ color: "Black" }}>Why Greases are essential </h1>
          <p className='info'>
          Greases are essential for the smooth and efficient operation of internal combustion engines, serving a multitude of critical functions that ensure the longevity, performance, and reliability of vehicles across various conditions and driving scenarios.Firstly, automotive oils act as lubricants, forming a protective barrier between moving engine parts, such as pistons, crankshafts, and camshafts, reducing friction and wear. This friction reduction not only extends the lifespan of engine components but also enhances fuel efficiency by minimizing energy losses due to frictional resistance.</p>
        </div>
      </div>
    );
}

const GearOilsPage = ({ imageUrl, heading, price }) => {
    
    const [selectedProduct, setSelectedProduct] = useState(null);
  
    const products = [
        { imageUrl: 'PICTURES-OJAS/80W90/80W90.jpg',imageUrl2: 'PICTURES-OJAS/80W90/80W90 (2).jpg', heading: 'OJAS ULTRA POWER SP GEAR OIL' , docxDownloadUrl: 'DATA-SHEET/data-sheet.pdf'},
        { imageUrl: 'barrel4.jpg',imageUrl2: 'barrel4.jpg', heading: 'OJAS ULTRA POWER TRANSMISSION GEAR OIL' , docxDownloadUrl: 'DATA-SHEET/data-sheet.pdf'},
        { imageUrl: 'barrel4.jpg',imageUrl2: 'barrel4.jpg', heading: 'GEAR OIL - OJAS C4 SAE 30' , docxDownloadUrl: 'DATA-SHEET/o.pdf'},

    ];

    const handlePdfDownload = (pdfDownloadUrl) => {
      // Constructing the URL based on the file location
      const url = `${process.env.PUBLIC_URL}/${pdfDownloadUrl}`;
      console.log("PDF Download URL:", url);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = 'data-sheet.pdf'; // Set the correct filename
      document.body.appendChild(link);
  
      link.addEventListener('error', (event) => {
          console.error('Error downloading file:', event);
          // Handle error here, such as displaying a message to the user
      });
  
      link.click();
      document.body.removeChild(link);
  };

    const handleProductClick = (product) => {
      setSelectedProduct(product);
    }
  
    const handleCloseModal = () => {
      setSelectedProduct(null);
    }
  
    return (
      <div>
         <div className='container'> 
        <img src='./GearOils.jpeg' className='products-img' alt=''/>
        </div>
        {/* <div className='container' style={{ height: '200px', width: '100%', position: 'relative', backgroundImage: 'url(bg-head.png)', backgroundSize: 'cover', backgroundPosition: 'center' , marginTop:'0px'}}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center' }}>
            <h1 className="future-head" style={{ color: "#031026" }}>Gear Oils</h1>
            <div className="underline" style={{ color: "#031026" }}></div>
          </div>
        </div> */}
  
        <div className='container'>
          <div className="row">
            {products.map((product, index) => (
              <ProductCard
                key={index}
                imageUrl={product.imageUrl}
                heading={product.heading}
                onClick={() => handleProductClick(product)}
              />
            ))}
          </div>
        </div>
  
        {selectedProduct && (
          <ProductDetail
          imageUrl={selectedProduct.imageUrl}
          imageUrl2={selectedProduct.imageUrl}
          imageUrl3={selectedProduct.imageUrl2}
            heading={selectedProduct.heading}
            onDownload={() => handlePdfDownload(selectedProduct.docxDownloadUrl)}
            onClose={handleCloseModal}
          />
        )}
  
        <div className='container'>
          <h1 className="future-head" style={{ color: "Black" }}>Why Gear Oils are essential </h1>
          <p className='info'>
          Gear oils are essential for the smooth and efficient operation of internal combustion engines, serving a multitude of critical functions that ensure the longevity, performance, and reliability of vehicles across various conditions and driving scenarios.Firstly, automotive oils act as lubricants, forming a protective barrier between moving engine parts, such as pistons, crankshafts, and camshafts, reducing friction and wear. This friction reduction not only extends the lifespan of engine components but also enhances fuel efficiency by minimizing energy losses due to frictional resistance.</p>
        </div>
      </div>
    );
}

const OtherProductsPage = ({ imageUrl, heading, price }) => {
    
    const [selectedProduct, setSelectedProduct] = useState(null);
  
    const products = [
        { imageUrl: 'barrel4.jpg',imageUrl2:'barrel4.jpg', heading: 'RUST PREVENTIVES',docxDownloadUrl: 'DATA-SHEET/data-sheet.pdf'},
        { imageUrl: 'barrel4.jpg',imageUrl2:'barrel4.jpg', heading: 'SOLUBLE CUTTING OIL',docxDownloadUrl: 'DATA-SHEET/clear cut cutting oil.pdf'},
        { imageUrl: 'barrel4.jpg',imageUrl2:'barrel4.jpg', heading: 'ANTI-FREEZE/COOLANT',docxDownloadUrl: 'DATA-SHEET/data-sheet.pdf'},
        { imageUrl: 'barrel4.jpg',imageUrl2:'barrel4.jpg', heading: 'ADDITIVES',docxDownloadUrl: 'DATA-SHEET/data-sheet.pdf'},
        { imageUrl: 'barrel4.jpg',imageUrl2:'barrel4.jpg', heading: 'SHUTTERING OIL',docxDownloadUrl: 'DATA-SHEET/OJAS SUPER POWER SHUTT OIL.pdf'},
        { imageUrl: 'barrel4.jpg',imageUrl2:'barrel4.jpg', heading: 'TRANS C4 SAE 30',docxDownloadUrl: 'DATA-SHEET/ojas trans c4 sae 30.pdf'},
    ];

    const handleProductClick = (product) => {
      setSelectedProduct(product);
    }
  
    const handleCloseModal = () => {
      setSelectedProduct(null);
    }

    const handlePdfDownload = (pdfDownloadUrl) => {
      // Constructing the URL based on the file location
      const url = `${process.env.PUBLIC_URL}/${pdfDownloadUrl}`;
      console.log("PDF Download URL:", url);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = 'data-sheet.pdf'; // Set the correct filename
      document.body.appendChild(link);
  
      link.addEventListener('error', (event) => {
          console.error('Error downloading file:', event);
          // Handle error here, such as displaying a message to the user
      });
  
      link.click();
      document.body.removeChild(link);
  };
  
    return (
      <div>
         <div className='container'> 
        <img src='./AllNeedOils2.jpeg' className='products-img' alt=''/>
        </div>
        {/* <div className='container' style={{ height: '200px', width: '100%', position: 'relative', backgroundImage: 'url(bg-head.png)', backgroundSize: 'cover', backgroundPosition: 'center' , marginTop:'0px'}}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center' }}>
            <h1 className="future-head" style={{ color: "#031026" }}>Other Products</h1>
            <div className="underline" style={{ color: "#031026" }}></div>
          </div>
        </div> */}
  
        <div className='container'>
          <div className="row">
            {products.map((product, index) => (
              <ProductCard
                key={index}
                imageUrl={product.imageUrl}
                heading={product.heading}
                onClick={() => handleProductClick(product)}
              />
            ))}
          </div>
        </div>
  
        {selectedProduct && (
          <ProductDetail
          imageUrl={selectedProduct.imageUrl}
          imageUrl2={selectedProduct.imageUrl}
          imageUrl3={selectedProduct.imageUrl2}
            heading={selectedProduct.heading}
            onDownload={() => handlePdfDownload(selectedProduct.docxDownloadUrl)}
            onClose={handleCloseModal}
          />
        )}
  
        <div className='container'>
          <h1 className="future-head" style={{ color: "Black" }}>Why Lubricants are essential </h1>
          <p className='info'>
Automotive oils are essential for the smooth and efficient operation of internal combustion engines, serving a multitude of critical functions that ensure the longevity, performance, and reliability of vehicles across various conditions and driving scenarios.Firstly, automotive oils act as lubricants, forming a protective barrier between moving engine parts, such as pistons, crankshafts, and camshafts, reducing friction and wear. This friction reduction not only extends the lifespan of engine components but also enhances fuel efficiency by minimizing energy losses due to frictional resistance.</p>
        </div>
      </div>
    );
}

export { LubricantsPage, IndustrialOilsPage, AutomotiveOilsPage, GreasesPage , GearOilsPage,OtherProductsPage };